<script setup>
import { inject } from 'vue';

const dayjs = inject('dayjs');

const props = defineProps({
    scanLog: Object,
});
</script>

<template>
    <div v-if="typeof scanLog != 'undefined' && scanLog.length > 0">
        <p class="mt-3 mb-3">{{ $t('QR Scan log') }}</p>
        <div v-for="log in scanLog" :key="log.id">
            <div class="flex gap-4" v-if="log.description === 'start_shift'">
                <span class="w-12 font-bold">{{ dayjs(log.created_at).format('HH:mm') }}</span>
                <span>{{ $t('In') }}</span>
            </div>

            <div class="flex gap-4" v-if="log.description === 'break_start'">
                <span class="w-12 font-bold">{{ dayjs(log.created_at).format('HH:mm') }}</span>
                <span>{{ $t('Out') }}</span>
            </div>

            <div class="flex gap-4" v-if="log.description === 'break_end'">
                <span class="w-12 font-bold">{{ dayjs(log.created_at).format('HH:mm') }}</span>
                <span>{{ $t('In') }}</span>
            </div>

            <div class="flex gap-4" v-if="log.description === 'end_shift'">
                <span class="w-12 font-bold">{{ dayjs(log.created_at).format('HH:mm') }}</span>
                <span>{{ $t('Out') }}</span>
            </div>
        </div>
    </div>
</template>